<template>
   <main>
		<HeaderTab 
            :title="componentTitle" 
            :back_action="onBack"
        />
        <div id="content">
            <shutter-panel />
        </div>
    </main>
</template>


<script type="text/javascript">
	import _debounce from 'lodash/debounce'
    import Notes from '@/mixins/Notes.js'
    import Shutter from '@/mixins/Shutter.js'
    import ShutterNotes from '@/mixins/shutters-manager/Notes.js'

	export default {
        name: "NoteManagement",
        mixins: [Notes, Shutter, ShutterNotes],
		data () {
			return {
                /* Main view params */
                componentTitle: '',

                /* Context params */
                horse_id: 0,
                actes_id: 0,
				note_id: 0,
				debouncedRedirect: null,
                tiers_id: 0,
                type: 0
			}
        },
		computed: {
			usage() {
				if(this.note_id && !this.horse_id && !this.tiers_id) {
					return 'preview'
				}
				else if(this.note_id && (this.horse_id || this.tiers_id)) {
					return 'edit'
				}
				return 'add'
			}
		},
        created() {
			this.debouncedRedirect = _debounce(this.redirectPostSave, 200)

            if(this.$route.params.acte_id) {
				this.actes_id = parseInt(this.$route.params.acte_id)
				this.actes_id = this.$sync.replaceWithReplicated('horse_actes', this.actes_id)
			}

            if(this.$route.params.horse_id) this.horse_id = parseInt(this.$route.params.horse_id)
			if(this.$route.params.note_id)  this.note_id  = parseInt(this.$route.params.note_id)
            if(this.$route.params.tiers_id) this.tiers_id = parseInt(this.$route.params.tiers_id)
            if(this.$route.params.type)     this.type     = parseInt(this.$route.params.type)
        },
        mounted() {
            this.init_component()
        },
        methods: {
            init_component() {
                switch (this.usage) {
					case 'preview': 
						this.setupNotePreview(this.note_id)
                        this.componentTitle = this.getTrad('notes.infos_note')
                        break
                    case 'edit':
                        if(this.horse_id) {
                            this.setupNoteEdit(this.note_id, this.horse_id, 0)
                        }
                        else {
                            this.setupNoteEdit(this.note_id, 0, this.tiers_id)
                        }
                        this.componentTitle = this.getTrad('notes.edit_note')
                        break
                    default:
                        if(this.horse_id || this.type == 1) {
                            this.setupHorseNoteAdd(this.horse_id, this.actes_id)
                        }
                        else if(this.tiers_id || this.type == 2) {
                            this.setupTiersNoteAdd(this.tiers_id)
                        }
                        this.componentTitle = this.getTrad('notes.add_note')
                        break
                }
            },

            async onShuttersSubmit(note_id) {
				await this.$sync.runOptionalWhishlist(['horse_note', 'files'])
				await this.updateNoteFilesIndex([note_id])
				this.debouncedRedirect()
            },

            redirectPostSave() {
                const from = this.$router.previous_route.name

                switch (from) {
                    case 'HorseFicheActes' :
                        this.$router.push({ name: from, params: this.horse_id })
                        break
                    case 'HorseFichePlanning' :
                        this.$router.push({ name: from, params: this.horse_id })
                        break
                    default :
                        this.$router.push({ name: from })
                }
            }
        },
        components: {
            HeaderTab: () => import('@/components/HeaderTab')
		},
    }
</script>
